function Kofi() {
  return (
    <div>
      <a href="https://ko-fi.com/S6S41W3O9" target="_blank" rel="noreferrer">
        <img
          height="36"
          style={{ border: "0px", height: "36px" }}
          src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"
          border="0"
          alt="Tip Me a Coffee at ko-fi.com"
        />
      </a>
    </div>
  );
}

export default Kofi;
